<!-- Web & tablet landscape -->
<div
  *ngIf="
    (isTabletLandscape || isWeb) &&
    courses.length > 0 &&
    categories.length > 0 &&
    !adminMode &&
    showVerticalMenu
  "
  class="relative flex h-full"
>
  <button
    *ngIf="!verticalMenuOpen && isWeb && animationDone"
    class="z-10 flex h-full w-5 items-center justify-center bg-brand-blue-550"
    (click)="toggleVerticalMenu()"
  >
    <img
      src="/assets/img/buttons/Reishauer_Icons-SubMenuRight.svg"
      class="h-4 w-4"
      alt="Submenu icon"
    />
  </button>
  <button
    *ngIf="isTabletLandscape"
    [ngClass]="verticalMenuOpen ? 'invisible' : 'visible'"
    class="flex h-full w-5 items-center justify-center bg-brand-blue-550"
    (click)="toggleVerticalMenu()"
  >
    <img
      src="/assets/img/buttons/Reishauer_Icons-SubMenuRight.svg"
      class="h-4 w-4"
      alt="Submenu icon"
    />
  </button>
  <div
    [hidden]="!verticalMenuOpen && animationDone"
    [@openClose]="verticalMenuOpen"
    (@openClose.start)="animationDone = false"
    (@openClose.done)="animationDone = true"
    [ngClass]="{
      'absolute z-50': isTabletLandscape,
      flex: verticalMenuOpen,
    }"
    class="w-[300px] h-full flex-col bg-brand-blue-550"
  >
    <div class="flex h-24 justify-end p-2">
      <button
        type="button"
        class="h-8 rounded-full bg-brand-blue-730 p-2"
        (click)="toggleVerticalMenu()"
      >
        <img
          src="/assets/img/buttons/Reishauer_Icons-SubMenuLeft.svg"
          class="h-4 w-4"
          alt="Submenu icon"
        />
      </button>
    </div>

    <div
      *ngIf="animationDone"
      class="overflow-y-auto overflow-x-hidden no-scrollbar"
    >
      <rh-vertical-menu-dropdown
        *ngFor="let category of filteredCategories"
        [category]="category"
        (toggle)="toggleCategory(category)"
        (selectedId)="scrollIntoView($event)"
        [isSmall]="false"
      >
      </rh-vertical-menu-dropdown>
    </div>
  </div>
</div>

<!-- Mobile & tablet portrait -->
<div
  *ngIf="
    (isMobile || isTabletPortrait) &&
    courses.length > 0 &&
    categories.length > 0 &&
    verticalMenuOpen &&
    !adminMode &&
    showVerticalMenu
  "
  class="fixed z-50 flex h-full w-full flex-col gap-10 bg-brand-blue-550"
>
  <div class="p-small flex flex-row items-center justify-between">
    <button (click)="logoClick()" class="flex h-12 items-center">
      <img
        src="/assets/img/Reishauer_Icons-LogoWhite.svg"
        alt="Logo"
        class="h-10"
      />
    </button>
    <button (click)="toggleVerticalMenu()" type="button" class="p-2">
      <img
        src="/assets/icons/Reishauer_Icons-Close.svg"
        alt="Close"
        class="aspect-square h-8"
      />
    </button>
  </div>

  <div class="overflow-auto">
    <rh-vertical-menu-dropdown
      *ngFor="let category of filteredCategories"
      [category]="category"
      (toggle)="toggleCategory(category)"
      (selectedId)="scrollIntoView($event)"
      [isSmall]="true"
    >
    </rh-vertical-menu-dropdown>
  </div>
</div>
