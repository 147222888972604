import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  debounceTime,
  distinctUntilChanged,
  from,
  lastValueFrom,
  map,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { Category } from '../../../../../app/shared/models/category.model';
import { User } from '../../../../../app/shared/models/user.model';
import {
  LoadingActiveAction,
  LoadingInactiveAction,
} from '../../../../../app/shared/store/core/actions';
import {
  ClearTemporaryMediaAssetUrlUpdateAction,
  DeleteMediaAssetAction,
  DeleteTemporaryMediaAssetAction,
  LoadMediaAssetsAction,
  MediaAssetResetStateAction,
  SaveMediaAssetsAction,
} from '../../../../../app/shared/store/media/actions';
import { State } from '../../../../../app/shared/store/state';
import { ConfirmCancelDialogComponent } from '../../../../core/components/confirm-cancel-dialog/confirm-cancel-dialog.component';
import { IConfirmCancelDialogData } from '../../../../core/components/confirm-cancel-dialog/core/confirm-cancel-dialog-data.interface';
import { IConfirmationResult } from '../../../../core/components/confirm-cancel-dialog/core/confirmation-result.model';
import { IMediaAssetDto } from '../../../../core/dto/media-asset-dto.model';
import { MediaTypeEnum } from '../../../../core/enums/media-type.enum';
import { DialogRef } from '../../../../core/services/dialog/dialog-ref';
import { DIALOG_DATA } from '../../../../core/services/dialog/dialog-tokens';
import { DialogService } from '../../../../core/services/dialog/dialog.service';
import { SnackbarType } from '../../../../core/services/snackbar/core/snackbar-type.enum';
import { SnackbarComponent } from '../../../../core/services/snackbar/snackbar.component';
import { SnackbarService } from '../../../../core/services/snackbar/snackbar.service';
import { MediaPreviewModalComponent } from '../media-preview-modal/media-preview-modal.component';
import { IMediaTile } from '../media-tile/media-tile.model';
import { IMediaModalData } from './media-modal-data.interface';

@Component({
  selector: 'rh-media-library-modal',
  templateUrl: './media-library-modal.component.html',
  styleUrls: ['./media-library-modal.component.scss'],
})
export class MediaLibraryModalComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  private tempAssetsUpdate$!: Observable<IMediaAssetDto[]>;
  private loadAssets$!: Observable<IMediaAssetDto[]>;
  private mediaAssetSavingProgress$!: Observable<number>;
  private errorSavingAssets$!: Observable<string>;

  public galleryView = true;
  public editMode = false;
  public MediaTypeEnums = MediaTypeEnum;
  public selectedMediaType?: { type: MediaTypeEnum; name: string };
  public searchText = '';
  public searchTextChange$ = new Subject();
  public selectedCategory?: Category;
  public selectedTimeFrame?: { value: number; name: string };
  public selectedEditor?: User;
  public showOnlyPrivate = false;

  private availableCategories$?: Observable<Category[]>;
  public availableCategories?: Category[];
  public availableFilterCategories?: Category[];
  private availableEditors$?: Observable<User[]>;
  public availableEditors: User[] = [];
  private user$!: Observable<User>;
  public user?: User; // used to check visibility of delete-buttun

  public sortByOptions = [
    { value: 'DATE', name: 'media.filters.sortBy.date' },
    { value: 'NAME', name: 'media.filters.sortBy.name' },
  ];
  public sortBy = this.sortByOptions[0];

  public sortDirOptions = [
    { value: 'DESC', name: 'media.filters.sortDir.desc' },
    { value: 'ASC', name: 'media.filters.sortDir.asc' },
  ];
  public sortDir = this.sortDirOptions[0];

  public tiles: IMediaTile[] = [];

  public tempUploadedTiles: IMediaTile[] = [];

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: IMediaModalData,
    private store$: Store<State>,
    public dialog: DialogService,
    private translator: TranslateService,
    private snackbar: SnackbarService,
  ) {
    this.selectedMediaType = { type: data.mediaType, name: data.mediaType };
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.close();
      event.stopImmediatePropagation();
      return false;
    }

    return true;
  }

  public async ngOnInit(): Promise<void> {
    this.availableCategories$ = this.store$.select((state) => {
      return state.Category.categories;
    });

    this.subscriptions.add(
      this.availableCategories$?.subscribe(async (categories: Category[]) => {
        this.availableCategories = categories;

        const allItem = {
          id: 0,
          name: await lastValueFrom(
            this.translator.get('media.filters.unselectedText'),
          ),
        } as Category;

        this.availableFilterCategories = [allItem, ...this.availableCategories];
      }),
    );

    this.availableEditors$ = this.store$.select((state) => {
      return state.Media.editors.map((m) => {
        return { name: m, accountName: m } as User;
      });
    });

    this.subscriptions.add(
      this.availableEditors$?.subscribe(async (editors: User[]) => {
        if (!editors || !editors.length) {
          return;
        }

        this.availableEditors = editors;
        this.availableEditors.unshift({
          name: await lastValueFrom(
            this.translator.get('media.filters.unselectedText'),
          ),
          accountName: '',
        } as User);
      }),
    );

    this.tempAssetsUpdate$ = this.store$.select((state) => {
      return state.Media.temporaryAssets;
    });

    this.subscriptions.add(
      this.tempAssetsUpdate$?.subscribe((update: IMediaAssetDto[]) => {
        if (!update) {
          return;
        }

        this.store$.dispatch(new LoadingInactiveAction());

        // Prepare the saved blobs/media for metadata/confnirmation view
        this.tempUploadedTiles = [];
        for (let i = 0; i < update.length; i++) {
          this.tempUploadedTiles.push({
            id: update[i].id,
            title: update[i].assetName,
            categoryId: update[i].categoryId,
            author: update[i].editor,
            uploaded: update[i].uploaded,
            private: update[i].private,
            mimeType: update[i].mimeType,
            imageUrl: update[i].assetUrl,
            displayImageUrl: update[i].assetDisplayUrl,
            enable: true,
          } as IMediaTile);
        }

        this.galleryView = this.tempUploadedTiles.length <= 0;
        this.editMode = false;
      }),
    );

    this.mediaAssetSavingProgress$ = this.store$.select((state) => {
      return state.Media.progressUpdate as number;
    });

    this.subscriptions.add(
      this.mediaAssetSavingProgress$?.subscribe((progress: number) => {
        if (progress === 100) {
          this.loadAssets();
        }
      }),
    );

    // Load assets
    this.loadAssets$ = this.store$.select((state) => {
      return state.Media.assets as IMediaAssetDto[];
    });

    this.subscriptions.add(
      this.loadAssets$?.subscribe((assets: IMediaAssetDto[]) => {
        this.tiles = assets.map((a) => {
          return {
            id: a.id,
            title: a.assetName,
            author: a.editor,
            imageUrl: a.assetUrl,
            displayImageUrl: a.assetDisplayUrl,
            mimeType: a.mimeType,
            categoryId: a.categoryId,
            categoryName: a.categoryName,
            uploaded: a.uploaded,
            private: a.private,
          } as IMediaTile;
        });

        this.store$.dispatch(new LoadingInactiveAction());
      }),
    );

    // Current user
    this.user$ = this.store$.select((state) => {
      return state.User.user as User;
    });

    this.subscriptions.add(
      this.user$?.subscribe((user: User) => {
        this.user = user;
        this.selectedEditor = {
          name: user?.email,
          accountName: user?.email,
        } as User;
      }),
    );

    // Searchtext change detection
    this.subscriptions.add(
      this.searchTextChange$
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          map(() => from(this.loadAssets())),
        )
        .subscribe(),
    );

    // Error handling
    this.errorSavingAssets$ = this.store$.select((state) => {
      return state.Media.error as string;
    });

    this.subscriptions.add(
      this.errorSavingAssets$?.subscribe((error: string) => {
        if (error) {
          this.store$.dispatch(new MediaAssetResetStateAction());
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `snackbar.mediaAssetOperationFailed`,
          });
        }
      }),
    );

    this.loadAssets();
  }

  public async onDeleteRequested(tile: IMediaTile) {
    const dialogRef = this.dialog.open(ConfirmCancelDialogComponent, {
      data: {
        title: await lastValueFrom(
          this.translator.get('media.delete.confirmationTitle'),
        ),
        text: await lastValueFrom(
          this.translator.get('media.delete.confirmationText'),
        ),
      } as IConfirmCancelDialogData,
    });

    const result = await lastValueFrom(dialogRef.afterClosed());

    if (!result.decision) {
      return;
    }

    this.store$.dispatch(new LoadingActiveAction());
    this.store$.dispatch(new DeleteMediaAssetAction({ id: tile.id }));
  }

  public getMediaType(mimeType: string): MediaTypeEnum {
    if (mimeType.indexOf('image') > -1) {
      return MediaTypeEnum.Image;
    } else if (mimeType.indexOf('video') > -1) {
      return MediaTypeEnum.Video;
    } else if (mimeType.indexOf('slide') > -1) {
      return MediaTypeEnum.Slide;
    }

    return MediaTypeEnum.Image;
  }

  public async onEditRequested(tile: IMediaTile) {
    tile.category = this.availableCategories?.filter(
      (c) => c.id === tile.categoryId,
    )[0];
    this.tempUploadedTiles = [JSON.parse(JSON.stringify(tile)) as IMediaTile];
    this.galleryView = this.tempUploadedTiles.length <= 0;
    this.editMode = true;
  }

  public async onViewRequested(tile: IMediaTile) {
    const dialogRef = this.dialog.open(
      MediaPreviewModalComponent,
      {
        data: { url: tile.displayImageUrl },
      },
      'custom-overlay-panel-dark',
    );

    await lastValueFrom(dialogRef.afterClosed());
  }

  public async onDeclineRequested(mediaAssetId: number) {
    this.store$.dispatch(
      new DeleteTemporaryMediaAssetAction({ mediaAssetId: mediaAssetId }),
    );
  }

  public async onConfirmationDone(selectedTiles: IMediaTile[]) {
    this.galleryView = true;
    this.store$.dispatch(new ClearTemporaryMediaAssetUrlUpdateAction());

    if (!selectedTiles.length) {
      return;
    }

    const items = selectedTiles.map((t) => {
      return {
        id: t.id,
        assetName: t.title,
        assetUrl: t.imageUrl,
        assetDisplayUrl: t.displayImageUrl,
        mimeType: t.mimeType,
        categoryId: t.categoryId,
        editor: '',
        uploaded: undefined,
        private: t.private,
      } as IMediaAssetDto;
    });

    this.store$.dispatch(new SaveMediaAssetsAction(items));
  }

  public async close(): Promise<void> {
    this.store$.dispatch(new ClearTemporaryMediaAssetUrlUpdateAction());
    this.dialogRef.close();
  }

  public async select(tile: IMediaTile): Promise<void> {
    if (!this.data.enableSelection) {
      return;
    }

    this.store$.dispatch(new ClearTemporaryMediaAssetUrlUpdateAction());
    this.dialogRef.close({
      type: tile.mimeType,
      payload: {
        name: tile.title,
        url: tile.imageUrl,
      },
    } as IConfirmationResult);
  }

  public isCreator(author: string): boolean {
    return author === this.user?.email;
  }

  public async ngOnDestroy(): Promise<void> {
    this.subscriptions?.unsubscribe();
  }

  public async onSearchTextChanged() {
    this.searchTextChange$.next(this.searchText);
  }

  public async loadAssets(): Promise<void> {
    console.log(this.user, this.selectedEditor);

    this.store$.dispatch(new LoadingActiveAction());
    this.store$.dispatch(
      new LoadMediaAssetsAction({
        mediaType: this.selectedMediaType?.type || '',
        searchText: this.searchText,
        categoryId: this.selectedCategory?.id || 0,
        timeFrame: this.selectedTimeFrame?.value || 0,
        editorId: this.selectedEditor?.accountName || '',
        privateOnly: this.showOnlyPrivate,
        sortBy: this.sortBy.value,
        sortDir: this.sortDir.value,
      }),
    );
  }
}
