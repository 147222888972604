import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { Observable } from 'rxjs';
import { ISlideModel } from '../models/slide.model';

@Injectable({
  providedIn: 'root',
})
export class PDFGeneratorService {
  generate(slides: ISlideModel[], title: string): Observable<any> {
    return new Observable((subscriber) => {
      setTimeout(async () => {
        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [1920, 1080],
        });

        for (let indexSlide = 0; indexSlide < slides.length; indexSlide++) {
          const slide = slides[indexSlide];

          if (!slide.displayThumbnail) continue;
          if (indexSlide !== 0) pdf.addPage();

          const image = await this.loadImage(slide.displayThumbnail);
          pdf.addImage(image, 'JPEG', 0, 0, 1920, 1080);
        }

        pdf.save(`${title}.pdf`);

        subscriber.next();
        subscriber.complete();
      }, 0);
    });
  }

  private async loadImage(src: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = src;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(image, 0, 0);
          resolve(canvas.toDataURL('image/jpeg'));
        } else {
          reject(new Error('Failed to get canvas context'));
        }
      };
      image.onerror = (error) => reject(error);
    });
  }
}
