import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { User } from '../../../shared/models/user.model';
import { LogOutUserAction } from '../../../shared/store/auth/actions';
import { State } from '../../../shared/store/state';
import { ChangeLanguageAction } from '../../../shared/store/user/actions';
import { ILanguageModel } from '../../models/language.model';

@Component({
  selector: 'rh-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css'],
})
export class UserMenuComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();

  @Output() toggleUserMenuEvent = new EventEmitter<void>();

  user$?: Observable<User | undefined>;
  user?: User;

  isPortrait = false;
  isLandscape = false;

  language$!: Observable<string>;
  selectedLanguage?: string;
  languages$!: Observable<ILanguageModel[]>;
  languages?: ILanguageModel[];

  constructor(
    private store$: Store<State>,
    private responsive: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.responsive
        .observe([
          Breakpoints.HandsetPortrait,
          Breakpoints.HandsetLandscape,
          Breakpoints.TabletPortrait,
        ])
        .subscribe((result) => {
          const breakpoints = result.breakpoints;
          this.isPortrait =
            breakpoints[Breakpoints.HandsetPortrait] ||
            breakpoints[Breakpoints.TabletPortrait];
          this.isLandscape = breakpoints[Breakpoints.HandsetLandscape];
        }),
    );

    this.user$ = this.store$.select((state) => {
      return state.User.user;
    });

    this.subscriptions.add(
      this.user$?.subscribe((user: User | undefined) => {
        if (user) {
          this.user = user;
        }
      }),
    );

    this.language$ = this.store$.select((state) => {
      return state.User.language;
    });

    this.subscriptions.add(
      this.language$?.subscribe((lang: string) => {
        this.selectedLanguage = lang;
      }),
    );

    this.languages$ = this.store$.select((state) => {
      return state.Language.languages as ILanguageModel[];
    });

    this.subscriptions.add(
      this.languages$?.subscribe((languages: ILanguageModel[]) => {
        this.languages = languages;
      })
    );
  }

  toggleUserMenu(): void {
    this.toggleUserMenuEvent.emit();
  }

  changeLanguage(lang: string): void {
    if (!this.user) return;
    this.store$.dispatch(new ChangeLanguageAction(lang, this.user.sub));
  }

  logout(): void {
    localStorage.clear();
    this.store$.dispatch(new LogOutUserAction());
  }
}
