import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { IAssetDto } from '../../../core/dto/asset-dto.model';
import { AssetService } from '../../services/asset/asset.service';
import { SlideService } from '../../services/slide/slide.service';
import { LoadingInactiveAction } from '../core/actions';
import { LoadCourseAction } from '../course/actions';
import {
  ActionTypes,
  DeleteSlideAction,
  DeleteSlideFailureAction,
  DeleteSlideSuccessAction,
  LoadSlideAssetsSuccessAction,
  PasteSlideAction,
  PasteSlideFailureAction,
  PasteSlideSuccessAction,
  SaveSlideAction,
  SaveSlideAssetsAction,
  SaveSlideAssetsFailureAction,
  SaveSlideAssetsSuccessAction,
  SaveSlideFailureAction,
  SaveSlideSuccessAction,
  SaveSlideThumbnailsAction,
  SaveSlidesAction,
  SaveSlidesSuccessAction,
  SaveTemporarySlideAssetAction,
  SaveTemporarySlideAssetProgressAction,
  SaveTemporarySlideAssetUrlUpdateAction,
  UpdateSlidesAction,
  UpdateSlidesFailureAction,
  UpdateSlidesSuccessAction,
} from './actions';

@Injectable()
export class SlideStoreEffects {
  constructor(
    private slideService: SlideService,
    private assetService: AssetService,
    private actions$: Actions,
  ) {}

  saveSlideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveSlideAction>(ActionTypes.SAVE_SLIDE),
      exhaustMap((action) =>
        this.slideService.SaveSlide(action.slide, action.courseId).pipe(
          map(
            (res) =>
              new SaveSlideSuccessAction(
                action.courseId,
                action.versionNumber,
                res.slideId,
              ),
          ),
          catchError((error) =>
            observableOf(new SaveSlideFailureAction({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  saveSlideSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveSlideSuccessAction>(ActionTypes.SAVE_SLIDE_SUCCESS),
      exhaustMap((action) => [
        new LoadCourseAction(action.courseId, action.versionNumber),
        new LoadingInactiveAction(),
      ]),
    ),
  );

  saveSlideFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveSlideFailureAction>(ActionTypes.SAVE_SLIDE_FAILURE),
      exhaustMap(() => [new LoadingInactiveAction()]),
    ),
  );

  saveSlidesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveSlidesAction>(ActionTypes.SAVE_SLIDES),
      switchMap((action) =>
        this.slideService.SaveSlides(action.newSlides, action.courseId).pipe(
          map(
            (res) =>
              new SaveSlidesSuccessAction(
                action.courseId,
                action.versionNumber,
                action.slideId,
                res.slides,
                action.newAssets,
                action.changedAssets,
              ),
          ),
          catchError((error) =>
            observableOf(new SaveSlideFailureAction({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  saveSlidesSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveSlidesSuccessAction>(ActionTypes.SAVE_SLIDES_SUCCESS),
      switchMap((action) => {
        const assets: IAssetDto[] = [];

        action.newAssets.forEach((assetContents, index: number) => {
          assetContents.forEach((asset) => {
            assets.push({
              ...asset,
              slideId: action.newSlideIds[index],
            } as IAssetDto);
          });
        });

        assets.push(...action.changedAssets);

        return [
          new SaveSlideAssetsAction({
            assets,
          }),
          new LoadCourseAction(action.courseId, action.versionNumber),
        ];
      }),
    ),
  );

  saveSlideAssetsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveSlideAssetsAction>(ActionTypes.SAVE_SLIDE_ASSETS),
      exhaustMap((action) =>
        this.assetService.SaveAssets(action.payload.assets).pipe(
          switchMap((savedAssets: IAssetDto[]) => {
            return [
              new LoadSlideAssetsSuccessAction({
                assets: savedAssets,
              }),
            ];
          }),
          catchError((error) =>
            observableOf(
              new SaveSlideAssetsFailureAction({ error: error.error }),
            ),
          ),
        ),
      ),
    ),
  );

  saveSlideThumbnailEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveSlideThumbnailsAction>(ActionTypes.SAVE_SLIDE_THUMBNAILS),
      exhaustMap((action) =>
        this.assetService.SaveThumbnails(action.slideId, action.thumbnail).pipe(
          map(
            () =>
              new SaveSlideAssetsSuccessAction({
                courseId: action.courseId,
              }),
          ),
          catchError((error) =>
            observableOf(
              new SaveSlideAssetsFailureAction({ error: error.error }),
            ),
          ),
        ),
      ),
    ),
  );

  pasteSlideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PasteSlideAction>(ActionTypes.PASTE_SLIDE),
      exhaustMap((action) =>
        this.slideService
          .PasteSlide(action.slideId, action.courseVersionId, action.position)
          .pipe(
            map(
              () =>
                new PasteSlideSuccessAction({
                  courseId: action.courseId,
                  versionNumber: action.versionNumber,
                }),
            ),
            catchError((error) =>
              observableOf(new PasteSlideFailureAction({ error: error.error })),
            ),
          ),
      ),
    ),
  );

  duplicateSlideSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PasteSlideSuccessAction>(ActionTypes.PASTE_SLIDE_SUCCESS),
      exhaustMap((action) => [
        new LoadCourseAction(
          action.payload.courseId,
          action.payload.versionNumber,
        ),
        new LoadingInactiveAction(),
      ]),
    ),
  );

  saveTemporarySlideAssetEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveTemporarySlideAssetAction>(
        ActionTypes.SAVE_TEMPORARY_SLIDE_ASSET,
      ),
      exhaustMap((action) => {
        return this.assetService
          .SaveTemporaryAsset(action.payload.asset.files)
          .pipe(
            map((event) => {
              if (event.type == HttpEventType.UploadProgress && event.total) {
                const progress = Math.round((100 / event.total) * event.loaded);
                return new SaveTemporarySlideAssetProgressAction({
                  progress: Math.min(progress, 98), // 100% is received when we get back the url, see below
                  placeholderCode: action.payload.asset.placeholderCode,
                });
              }

              // Final event when 100% & Success is reported
              if (event.type == HttpEventType.Response) {
                new SaveTemporarySlideAssetProgressAction({
                  progress: 100,
                  placeholderCode: action.payload.asset.placeholderCode,
                });

                return new SaveTemporarySlideAssetUrlUpdateAction({
                  urls: (event.body as { result: string[] })?.result,
                  placeholderCode: action.payload.asset.placeholderCode,
                });
              }

              // Fallback/placeholder case, which is not represented in the UI (but we need to return something here...)
              return new SaveTemporarySlideAssetProgressAction({
                progress: 0,
                placeholderCode: action.payload.asset.placeholderCode,
              });
            }),
            catchError((error) =>
              observableOf(
                new SaveSlideAssetsFailureAction({ error: error.error }),
              ),
            ),
          );
      }),
    ),
  );

  duplicateSlideFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PasteSlideFailureAction>(ActionTypes.PASTE_SLIDE_FAILURE),
      exhaustMap(() => [new LoadingInactiveAction()]),
    ),
  );

  saveSlideAssetsSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveSlideAssetsSuccessAction>(
        ActionTypes.SAVE_SLIDE_ASSETS_SUCCESS,
      ),
      exhaustMap(() => [new LoadingInactiveAction()]),
    ),
  );

  saveSlideAssetsFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveSlideAssetsFailureAction>(
        ActionTypes.SAVE_SLIDE_ASSETS_FAILURE,
      ),
      exhaustMap(() => [new LoadingInactiveAction()]),
    ),
  );

  updateSlidesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateSlidesAction>(ActionTypes.UPDATE_SLIDES),
      exhaustMap((action) =>
        this.slideService
          .SaveSlides(action.payload.slides, action.payload.courseId)
          .pipe(
            map(
              () =>
                new UpdateSlidesSuccessAction({
                  courseId: action.payload.courseId,
                  versionNumber: action.payload.versionNumber,
                }),
            ),
            catchError((error) =>
              observableOf(
                new UpdateSlidesFailureAction({ error: error.error }),
              ),
            ),
          ),
      ),
    ),
  );

  updateSlidesSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateSlidesSuccessAction>(ActionTypes.UPDATE_SLIDES_SUCCESS),
      exhaustMap((action) => [
        new LoadCourseAction(
          action.payload.courseId,
          action.payload.versionNumber,
        ),
        new LoadingInactiveAction(),
      ]),
    ),
  );

  updateSlidesFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateSlidesFailureAction>(ActionTypes.UPDATE_SLIDES_FAILURE),
      map(() => new LoadingInactiveAction()),
    ),
  );

  deleteSlideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteSlideAction>(ActionTypes.DELETE_SLIDE),
      exhaustMap((action) =>
        this.slideService.DeleteSlide(action.payload.slide).pipe(
          exhaustMap(() => [
            new DeleteSlideSuccessAction({
              courseId: action.payload.courseId,
              versionNumber: action.payload.versionNumber,
            }),
          ]),
          catchError((error) =>
            observableOf(new DeleteSlideFailureAction({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  deleteSlideSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteSlideSuccessAction>(ActionTypes.DELETE_SLIDE_SUCCESS),
      exhaustMap((action) => [
        new LoadCourseAction(
          action.payload.courseId,
          action.payload.versionNumber,
        ),
        new LoadingInactiveAction(),
      ]),
    ),
  );

  deleteSlideFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteSlideFailureAction>(ActionTypes.DELETE_SLIDE_FAILURE),
      exhaustMap(() => [new LoadingInactiveAction()]),
    ),
  );
}
