<div class="relative flex flex-col h-10 z-50" *ngIf="selectedLanguage">
  <div
    [ngClass]="
      showLanguageDropdown
        ? 'absolute origin-top-right top-0 right-0 w-[200px]'
        : ''
    "
  >
    <button
      type="button"
      [ngClass]="
        showLanguageDropdown ? 'bg-brand-blue-550 rounded-t-md w-full' : ''
      "
      class="flex p-2 items-center text-[13px] h-10 gap-2 justify-between"
      (click)="toggleLanguageDropdown()"
    >
      <img
        src="/assets/icons/Reishauer_Icons-Language.svg"
        alt="Language"
        class="w-5 h-5"
      />

      <span *ngIf="!showLanguageDropdown" class="text-brand-blue">
        {{ selectedLanguage.name }}
      </span>

      <img
        *ngIf="showLanguageDropdown"
        src="/assets/icons/Reishauer_Icons-Close.svg"
        class="w-4 h-4"
      />
    </button>

    <div
      *ngIf="showLanguageDropdown"
      class="options-list w-full text-[13px] leading-[13px] text-white rounded-b-md bg-brand-blue-550 shadow-lg ring-1 ring-gray-900/5 focus:outline-none overflow-y-auto"
    >
      <div
        *ngFor="let language of languages"
        (click)="changeLanguage(language); toggleLanguageDropdown()"
        class="flex items-center justify-between px-4 h-11 text-white cursor-pointer"
        [ngClass]="
          selectedLanguage.key === language.key
            ? ' bg-[#7994C9]'
            : ' hover:bg-[#536EA9]'
        "
      >
        <span>{{ language.name }}</span>
        <img
          src="/assets/img/Reishauer_Icons-Check.png"
          alt="Language selected"
          class="w-[24px] h-[24px]"
          *ngIf="selectedLanguage.key === language.key"
        />
      </div>
    </div>
  </div>
</div>
