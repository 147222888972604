import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MediaTypeEnum } from '../../../../core/enums/media-type.enum';

@Component({
  selector: 'rh-media-tile',
  templateUrl: './media-tile.component.html',
  styleUrls: ['./media-tile.component.scss'],
})
export class MediaTileComponent {
  @Input() public title? = 'Title';
  @Input() public category? = 'Category';
  @Input() public author? = 'Author';
  @Input() public uploaded? = '';
  @Input() public private = false;
  @Input() public showDeleteButton = false;
  @Input() public mediaType: MediaTypeEnum = MediaTypeEnum.Image;

  @Input()
  public set url(value: string) {
    this._imageUrl = value || this.fallbackImageUrl;
  }

  public get url() {
    return this._imageUrl;
  }

  @Input() public color = '#FFFFFF';
  @Input() public editMode = false;

  private _imageUrl = '';
  private fallbackImageUrl =
    './assets/img/template-backgrounds/Reishauer_Haupttitel.jpg';

  @Output()
  public deleteRequested: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public editRequested: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public viewRequested: EventEmitter<void> = new EventEmitter<void>();

  mediaTypeEnums = MediaTypeEnum;

  public async delete(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.deleteRequested?.emit();
  }

  public async edit(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.editRequested?.emit();
  }

  public async view(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.viewRequested?.emit();
  }
}
