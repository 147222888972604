import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ILanguageModel } from '../../../core/models/language.model';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private http: HttpClient) {}

  GetLanguages(): Observable<ILanguageModel[]> {
    return this.http
      .get<
        ILanguageModel[]
      >(`${environment.backendApiUrl}/GetLanguages?code=${environment.apiKey}`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
