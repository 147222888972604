import { Component, Inject } from '@angular/core';
import { DialogRef } from '../../../../core/services/dialog/dialog-ref';
import { DIALOG_DATA } from '../../../../core/services/dialog/dialog-tokens';
import { IMediaPreviewModalData } from './media-preview-modal-data';

@Component({
  selector: 'rh-media-preview-modal',
  templateUrl: './media-preview-modal.component.html',
  styleUrls: ['./media-preview-modal.component.scss'],
})
export class MediaPreviewModalComponent {
  public url = '';
  public fallbackImageUrl = '';

  public isVideo = false;

  constructor(
    @Inject(DIALOG_DATA) public data: IMediaPreviewModalData,
    private dialogRef: DialogRef,
  ) {
    this.url = data.url;
    this.isVideo = this.url?.indexOf('.mp4') > 0 || false;
  }

  public async close(): Promise<void> {
    this.dialogRef.close();
  }
}
