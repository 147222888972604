import { Action } from '@ngrx/store';
import { ILanguageModel } from '../../../core/models/language.model';

export enum ActionTypes {
  LOAD_LANGUAGES = '[Languages] Load Languages',
  LOAD_LANGUAGES_SUCCESS = '[Languages] Load Languages Success',
  LOAD_LANGUAGES_FAILURE = '[Languages] Load Languages Failure',
}

export class LoadLanguagesAction implements Action {
  readonly type = ActionTypes.LOAD_LANGUAGES;
}

export class LoadLanguagesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_LANGUAGES_SUCCESS;
  constructor(public languages: ILanguageModel[]) {}
}

export class LoadLanguagesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_LANGUAGES_FAILURE;
  constructor(public error: string) {}
}

export type Actions =
  | LoadLanguagesAction
  | LoadLanguagesSuccessAction
  | LoadLanguagesFailureAction;
