import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { IMediaAssetDto } from '../../../core/dto/media-asset-dto.model';
import { AssetService } from '../../services/asset/asset.service';
import { LoadingInactiveAction } from '../core/actions';
import {
  ActionTypes,
  CopySlideAction,
  CopySlideFailureAction,
  CopySlideSuccessAction,
  DeleteMediaAssetAction,
  DeleteMediaAssetFailureAction,
  DeleteMediaAssetSuccessAction,
  DeleteTemporaryMediaAssetAction,
  DeleteTemporaryMediaAssetFailureAction,
  DeleteTemporaryMediaAssetSuccessAction,
  LoadMediaAssetsAction,
  LoadMediaAssetsFailureAction,
  LoadMediaAssetsSuccessAction,
  LoadMediaEditorsAction,
  LoadMediaEditorsFailureAction,
  LoadMediaEditorsSuccessAction,
  SaveMediaAssetsAction,
  SaveMediaAssetsFailureAction,
  SaveMediaAssetsSuccessAction,
  SaveTemporaryMediaAssetAction,
  SaveTemporaryMediaAssetProgressAction,
  SaveTemporaryMediaAssetsUpdateAction,
} from './actions';

@Injectable()
export class MediaStoreEffects {
  constructor(
    private mediaAssetService: AssetService,
    private actions$: Actions,
  ) {}

  saveTemporaryMediaAssetEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveTemporaryMediaAssetAction>(
        ActionTypes.SAVE_TEMPORARY_MEDIA_ASSET,
      ),
      exhaustMap((action) => {
        return this.mediaAssetService
          .SaveTemporaryMediaAsset(action.payload.asset.files)
          .pipe(
            map((event) => {
              if (event.type == HttpEventType.UploadProgress && event.total) {
                const progress = Math.round((100 / event.total) * event.loaded);
                return new SaveTemporaryMediaAssetProgressAction({
                  progress: Math.min(progress, 98), // 100% is received when we get back the url, see below
                });
              }

              // Final event when 100% & Success is reported
              if (event.type == HttpEventType.Response) {
                new SaveTemporaryMediaAssetProgressAction({
                  progress: 100,
                });

                return new SaveTemporaryMediaAssetsUpdateAction({
                  assets: (event.body as { result: IMediaAssetDto[] })?.result,
                });
              }

              // Fallback/placeholder case, which is not represented in the UI (but we need to return something here...)
              return new SaveTemporaryMediaAssetProgressAction({
                progress: 0,
              });
            }),
            catchError((error) =>
              observableOf(
                new SaveMediaAssetsFailureAction({ error: error.error }),
              ),
            ),
          );
      }),
    ),
  );

  deleteTemporaryMediaAssetEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteTemporaryMediaAssetAction>(
        ActionTypes.DELETE_TEMPORARY_MEDIA_ASSET,
      ),
      exhaustMap((action) => {
        return this.mediaAssetService
          .DeleteTemporaryMediaAsset(action.payload.mediaAssetId)
          .pipe(
            map(() => {
              return new DeleteTemporaryMediaAssetSuccessAction();
            }),
            catchError((error) =>
              observableOf(
                new DeleteTemporaryMediaAssetFailureAction({
                  error: error.error,
                }),
              ),
            ),
          );
      }),
    ),
  );

  loadMediaAssetsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadMediaAssetsAction>(ActionTypes.LOAD_MEDIA_ASSETS),
      exhaustMap((action) =>
        this.mediaAssetService
          .GetMediaAssets(
            action.payload.mediaType,
            action.payload.searchText,
            action.payload.categoryId,
            action.payload.timeFrame,
            action.payload.editorId,
            action.payload.privateOnly,
            action.payload.sortBy,
            action.payload.sortDir,
          )
          .pipe(
            map((items) => new LoadMediaAssetsSuccessAction(items)),
            catchError((error) =>
              observableOf(new LoadMediaAssetsFailureAction(error.error)),
            ),
          ),
      ),
    ),
  );

  loadMediaAssetsSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LoadMediaAssetsSuccessAction>(
          ActionTypes.LOAD_MEDIA_ASSETS_SUCCESS,
        ),
        switchMap((action) => action.assets),
      ),
    { dispatch: false },
  );

  saveMediaAssetsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveMediaAssetsAction>(ActionTypes.SAVE_MEDIA_ASSETS),
      exhaustMap((action) => {
        return this.mediaAssetService.SaveMediaAssets(action.assets).pipe(
          map(() => {
            return new SaveMediaAssetsSuccessAction();
          }),
          catchError((error) =>
            observableOf(
              new SaveMediaAssetsFailureAction({ error: error.error }),
            ),
          ),
        );
      }),
    ),
  );

  saveMediaAssetFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveMediaAssetsFailureAction>(
        ActionTypes.SAVE_MEDIA_ASSETS_FAILURE,
      ),
      exhaustMap(() => [new LoadingInactiveAction()]),
    ),
  );

  copySlideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CopySlideAction>(ActionTypes.COPY_SLIDE),
      exhaustMap((action) => {
        return this.mediaAssetService.SaveMediaAssets(action.assets).pipe(
          map(() => {
            return new CopySlideSuccessAction();
          }),
          catchError((error) =>
            observableOf(new CopySlideFailureAction({ error: error.error })),
          ),
        );
      }),
    ),
  );

  deleteMediaAssetsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteMediaAssetAction>(ActionTypes.DELETE_MEDIA_ASSET),
      exhaustMap((action) => {
        return this.mediaAssetService.DeleteMediaAsset(action.payload.id).pipe(
          map(() => {
            return new DeleteMediaAssetSuccessAction();
          }),
          catchError((error) =>
            observableOf(
              new DeleteMediaAssetFailureAction({ error: error.error }),
            ),
          ),
        );
      }),
    ),
  );

  loadMediaEditorsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadMediaEditorsAction>(ActionTypes.LOAD_MEDIA_EDITORS),
      exhaustMap(() =>
        this.mediaAssetService.GetMediaAssetEditors().pipe(
          map((items) => new LoadMediaEditorsSuccessAction(items)),
          catchError((error) =>
            observableOf(new LoadMediaEditorsFailureAction(error.error)),
          ),
        ),
      ),
    ),
  );

  loadEditorsSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LoadMediaEditorsSuccessAction>(
          ActionTypes.LOAD_MEDIA_EDITORS_SUCCESS,
        ),
        switchMap((action) => action.editors),
      ),
    { dispatch: false },
  );
}
