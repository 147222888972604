import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_LANGUAGES: {
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    }
    case ActionTypes.LOAD_LANGUAGES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        languages: action.languages,
      };
    }
    case ActionTypes.LOAD_LANGUAGES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}
