import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Language } from '../models/language';

@Component({
  selector: 'rh-languagedropdown-desktop',
  templateUrl: './language-dropdown-desktop.component.html',
  styleUrls: ['./language-dropdown-desktop.component.scss'],
})
export class LanguageDropdownDesktopComponent {
  public listVisible = false;

  selectedLanguage?: Language;

  showLanguageDropdown = false;

  @Input() languages: Language[] = [];
  @Output() languageChanged: EventEmitter<Language> = new EventEmitter();

  private _selectedLanguageKey?: string;

  @Input()
  public set selectedLanguageKey(value: string) {
    this._selectedLanguageKey = value;
    this.selectedLanguage = this.languages.find(
      (lang) => lang.key === value
    ) as Language;
  }

  public get selectedLanguageKey(): string {
    return this._selectedLanguageKey || '';
  }

  toggleLanguageDropdown(): void {
    this.showLanguageDropdown = !this.showLanguageDropdown;
  }

  changeLanguage(language: Language): void {
    this.languageChanged.emit(language);
  }

  public async toggle() {
    this.listVisible = !this.listVisible;
  }

  public async select(item: Language) {
    this.listVisible = false;

    if (this.selectedLanguageKey === item.key) {
      return;
    }

    this.selectedLanguageKey = item.key;
    this.languageChanged?.emit(item);
  }

  isSelected(item: Language): boolean {
    return this.selectedLanguageKey === item.key;
  }

  public onFocusOutEvent() {
    // We need to give the click-event precedence, otherwise it will not be fired
    setTimeout(() => {
      this.listVisible = false;
    }, 200);
  }
}
