<!-- desktop & tablet landscape -->
<header *ngIf="isWeb || isTabletLandscape" class="px-big bg-white">
  <div *ngIf="normalHeader" class="flex h-24 items-center justify-between py-3">
    <div>
      <h1
        class="mb-1 font-brand-thin text-[34px] uppercase leading-[34px] text-brand-blue-600"
      >
        {{ 'header.title' | translate }}
        <span class="text-brand-header-blue"> | {{ title | translate }} </span>
      </h1>
    </div>
    <div class="flex items-center gap-2">
      <!-- language dropdown -->
      <rh-languagedropdown-desktop
        *ngIf="languages && languages.length > 0 && selectedLanguageKey"
        [languages]="languages"
        [selectedLanguageKey]="selectedLanguageKey"
        (languageChanged)="changeLanguage($event.key)"
      ></rh-languagedropdown-desktop>
      <!-- user dropdown -->
      <rh-userdropdown
        *ngIf="user && permissions"
        [username]="user.name"
        [company]="permissions.organizationName"
        (userLoggedOut)="logout()"
      ></rh-userdropdown>
    </div>
  </div>

  <div *ngIf="!normalHeader" class="h-[80px] items-center flex justify-between">
    <div class="flex gap-10">
      <img
        src="../assets/img/Reishauer_Logo.svg"
        class="cursor-pointer"
        (click)="logoClick()"
      />
      <div
        class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-[34px] leading-[37px] font-brand-thin text-brand-blue-600 uppercase"
      >
        {{ title }}{{ chapterTitle }}
      </div>
    </div>

    <div class="flex flex-row gap-4 items-center">
      <rh-approval-controls
        *ngIf="(approverMode || publishMode) && isApprover"
        [publishMode]="publishMode"
        (approvalRequested)="approve()"
        (rejectionRequested)="reject()"
      ></rh-approval-controls>
      <button
        *ngIf="showPreviewControl"
        (click)="togglePreviewMode()"
        class="justify-center items-center flex rounded-full border w-10 h-10 p-1 border-brand-blue-800"
      >
        @if (previewMode) {
          <img
            src="/assets/img/buttons/Reishauer_Icons_Edit_Blue.svg"
            alt="Edit"
            class="aspect-square h-6"
          />
        } @else {
          <img
            src="/assets/img/buttons/Reishauer_Icons_Preview_Blue.svg"
            alt="Preview"
            class="aspect-square h-8"
          />
        }
      </button>
      <div
        class="text-[34px] leading-[34px] font-brand-thin text-brand-blue-600 uppercase"
        *ngIf="showSlideCount"
      >
        {{ slideCountText }}
      </div>
    </div>
  </div>
</header>

<!-- mobile & tablet portrait -->
<header *ngIf="isMobile || isTabletPortrait" class="bg-white p-small">
  <div *ngIf="normalHeader" class="flex w-full flex-row justify-between">
    <div
      [ngClass]="{
        'flex-row': isMobileLandscape,
        'flex-col': isMobilePortrait || isTabletPortrait,
      }"
      class="flex gap-2"
    >
      <button (click)="logoClick()" class="flex h-12 items-center">
        <img src="../assets/img/Reishauer_Logo.svg" alt="Logo" class="h-10" />
      </button>
      <div
        class="font-brand-thin text-[25px] sm:text-[18px] h-12 uppercase leading-[25px] text-brand-blue-600"
        [ngClass]="{
          'flex items-center gap-1': isMobileLandscape,
        }"
      >
        <p>{{ 'header.title' | translate }}</p>
        <span
          [ngClass]="{
            'text-brand-header-blue': isMobileLandscape,
          }"
          class="flex items-center gap-1"
        >
          <span *ngIf="isMobileLandscape">|</span>
          <p>{{ title | translate }}</p>
        </span>
      </div>
    </div>

    <div class="flex flex-col gap-2 items-end">
      <div class="flex gap-2 justify-between">
        <button
          *ngIf="enableVerticalMenu"
          (click)="toggleVerticalMenu()"
          type="button"
          class="aspect-square h-10 mr-0.5 rounded-full border border-brand-blue-800 p-1.5"
        >
          <img
            src="/assets/icons/Reishauer_Icons-Filter.svg"
            alt="User menu"
            class="aspect-square h-full"
          />
        </button>
        <div class="flex flex-row gap-2 p-0.5 rounded-full bg-[#2c4476]">
          <button
            (click)="toggleUserMenu()"
            type="button"
            class="aspect-square h-10 rounded-full border border-[#808fad] hover:bg-[#808fad] p-1.5"
          >
            <img
              src="/assets/icons/Reishauer_Icons-User-White.svg"
              alt="Vertical menu"
              class="aspect-square h-full"
            />
          </button>
          <button
            (click)="toggleSidebar()"
            type="button"
            class="aspect-square h-10 rounded-full border border-[#808fad] hover:bg-[#808fad] p-1.5"
          >
            <img
              src="/assets/img/buttons/Reishauer_Icons-Menu.svg"
              alt="Menu"
              class="aspect-square h-full"
            />
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="!normalHeader"
    [ngClass]="{
      'items-center': isMobileLandscape,
      'items-end': isMobilePortrait || isTabletPortrait,
    }"
    class="flex justify-between font-brand-thin text-[25px] sm:text-[18px] uppercase leading-[25px] text-brand-blue-600"
  >
    <div
      [ngClass]="{
        'flex-col': isMobilePortrait || isTabletPortrait,
        'flex-row items-center': isMobileLandscape,
      }"
      class="flex gap-3"
    >
      <button (click)="logoClick()" class="flex shrink-0 h-12 items-center">
        <img src="../assets/img/Reishauer_Logo.svg" alt="Logo" class="h-10" />
      </button>
      <div
        class="h-full w-full whitespace-nowrap text-ellipsis overflow-hidden"
      >
        {{ title }}{{ chapterTitle }}
      </div>
    </div>
    <div *ngIf="showSlideCount">
      {{ slideCountText }}
    </div>
  </div>
</header>
