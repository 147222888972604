import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Language } from '../models/language';

@Component({
  selector: 'rh-language-dropdown-mobile',
  templateUrl: './language-dropdown-mobile.component.html',
  styleUrls: ['./language-dropdown-mobile.component.css'],
})
export class LanguageDropdownMobileComponent {
  showLanguageDropdown = false;

  selectedLanguage: Language = {
    name: 'English',
    key: 'en',
  };

  @Input() languages: Language[] = [];
  @Output() languageChanged: EventEmitter<Language> = new EventEmitter();

  private _selectedLanguageKey = 'en';

  @Input()
  public set selectedLanguageKey(value: string) {
    this._selectedLanguageKey = value;
    this.selectedLanguage =
      this.languages.find((lang) => lang.key === value) ||
      this.selectedLanguage;
  }

  public get selectedLanguageKey(): string {
    return (
      this.languages.find((lang) => lang.key === this._selectedLanguageKey)
        ?.key || this.selectedLanguage.key
    );
  }

  changeLanguage(language: Language): void {
    this.languageChanged.emit(language);
    this.selectedLanguage = language;
    this.selectedLanguageKey = language.key;
    this.toggleLanguageDropdown();
  }

  public async toggleLanguageDropdown() {
    this.showLanguageDropdown = !this.showLanguageDropdown;
  }

  public async select(item: Language) {
    this.showLanguageDropdown = false;

    if (this.selectedLanguageKey === item.key) {
      return;
    }

    this.selectedLanguageKey = item.key;
    this.languageChanged?.emit(item);
  }

  isSelected(item: Language): boolean {
    return this.selectedLanguageKey === item.key;
  }

  public onFocusOutEvent() {
    // We need to give the click-event precedence, otherwise it will not be fired
    setTimeout(() => {
      this.showLanguageDropdown = false;
    }, 200);
  }
}
