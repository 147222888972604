import { environment } from '../../../../environments/environment';
import { IMediaAssetDto } from '../../../core/dto/media-asset-dto.model';
import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.RESET_ERROR_STATE: {
      return {
        ...state,
        error: undefined,
      };
    }

    case ActionTypes.SAVE_TEMPORARY_MEDIA_ASSET_PROGRESS: {
      return {
        ...state,
        progressUpdate: action.payload.progress,
      };
    }

    case ActionTypes.SAVE_TEMPORARY_MEDIA_ASSETS_UPDATE: {
      console.log('action.payload.assets', action.payload.assets);
      return {
        ...state,
        temporaryAssets: action.payload.assets.map((asset) => ({
          ...asset,
          assetDisplayUrl: `${environment.backendApiUrl}/BlobAssets/${asset.assetUrl}/`,
        })),
        progressUpdate: undefined,
      };
    }

    case ActionTypes.DELETE_TEMPORARY_MEDIA_ASSET_SUCESS: {
      return {
        ...state,
        isLoading: false,
        progressUpdate: undefined,
      };
    }

    case ActionTypes.SAVE_MEDIA_ASSETS: {
      return {
        ...state,
        progressUpdate: 0,
      };
    }

    case ActionTypes.SAVE_MEDIA_ASSETS_SUCESS: {
      return {
        ...state,
        isLoading: false,
        progressUpdate: 100,
      };
    }

    case ActionTypes.SAVE_MEDIA_ASSETS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        temporaryAssets: [],
        progressUpdate: undefined,
      };
    }
    case ActionTypes.COPY_SLIDE: {
      return {
        ...state,
        progressUpdate: 0,
        copySlideSuccessMessage: undefined,
      };
    }
    case ActionTypes.COPY_SLIDE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        progressUpdate: 100,
        copySlideSuccessMessage: 'snackbar.slideCopied',
      };
    }
    case ActionTypes.COPY_SLIDE_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        progressUpdate: undefined,
      };
    }

    case ActionTypes.DELETE_MEDIA_ASSET: {
      return {
        ...state,
        progressUpdate: 0,
      };
    }

    case ActionTypes.DELETE_MEDIA_ASSET_SUCESS: {
      return {
        ...state,
        isLoading: false,
        progressUpdate: 100,
      };
    }

    case ActionTypes.DELETE_MEDIA_ASSET_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        temporaryAssets: [],
        progressUpdate: undefined,
      };
    }

    case ActionTypes.CLEAR_TEMPORARY_MEDIA_ASSETS_URL_UPDATE: {
      return {
        ...state,
        isLoading: false,
        temporaryAssets: [],
        progressUpdate: undefined,
      };
    }

    case ActionTypes.LOAD_MEDIA_ASSETS: {
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    }
    case ActionTypes.LOAD_MEDIA_ASSETS_SUCCESS: {
      return {
        ...state,
        assets: action.assets.map((a) => transformImageUrl(a)),
        isLoading: false,
      };
    }
    case ActionTypes.LOAD_MEDIA_ASSETS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    }
    case ActionTypes.LOAD_MEDIA_EDITORS: {
      return {
        ...state,
        isLoading: true,
        editors: [],
        error: '',
      };
    }
    case ActionTypes.LOAD_MEDIA_EDITORS_SUCCESS: {
      return {
        ...state,
        editors: action.editors,
        isLoading: false,
      };
    }
    case ActionTypes.LOAD_MEDIA_EDITORS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    }

    case ActionTypes.CLEAR_MESSAGES: {
      return {
        ...state,
        error: undefined,
        copySlideSuccessMessage: undefined,
      };
    }

    default: {
      return state;
    }
  }
}

function transformImageUrl(tile: IMediaAssetDto): IMediaAssetDto {
  const imageUrl =
    tile.assetUrl?.indexOf('assets/img') === -1
      ? `${environment.backendApiUrl}/BlobAssets/${tile.assetUrl}`
      : tile.assetUrl;

  return {
    ...tile,
    assetDisplayUrl: imageUrl,
  };
}
