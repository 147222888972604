import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Category } from '../../models/category.model';
import { Subcategory } from '../../models/subcategory.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private http: HttpClient) {}

  SyncCategories(): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/SyncCategories?code=${environment.apiKey}`,
        {},
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetCategories(): Observable<Category[]> {
    return this.http
      .get<
        {
          id: number[];
          name: string[];
          languageId: number[];
          subcategoryId: number;
          subcategoryCategoryId: number;
          subcategoryName: string;
          subcategoryFeatureKey: string;
        }[]
      >(`${environment.backendApiUrl}/GetCategories?code=${environment.apiKey}`)
      .pipe(
        map((response) => {
          const categories: Category[] = [];

          response.forEach((element) => {
            const values = response.filter(
              (s) => s.subcategoryCategoryId === element.id[0],
            );

            const subcategories: Subcategory[] = [];

            for (
              let indexCategory = 0;
              indexCategory < values.length;
              indexCategory++
            ) {
              const element = values[indexCategory];
              subcategories.push({
                id: element.subcategoryId,
                name: element.subcategoryName,
                featureKey: element.subcategoryFeatureKey,
                categoryId: element.subcategoryCategoryId,
                isEditing: false,
              });
            }

            if (!categories.find((c) => c.id == element.id[0])) {
              categories.push({
                id: element.id[0],
                name: element.name[0],
                subcategories: subcategories,
                toggled: false,
                selected: false,
                languageId: element.languageId[0],
              });
            }
          });

          // sort subcategories by name
          categories.forEach((category) => {
            if (category?.subcategories) {
              category.subcategories = category.subcategories.sort((a, b) =>
                a.name.localeCompare(b.name),
              );
            }
          });

          return categories;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveCategory(category: Category): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/SaveCategory?id=${category.id}&code=${environment.apiKey}`,
        { category: category },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteCategory(id: number): Observable<object> {
    return this.http
      .delete(
        `${environment.backendApiUrl}/DeleteCategory?id=${id}&code=${environment.apiKey}`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteSubcategory(id: number): Observable<object> {
    return this.http
      .delete(
        `${environment.backendApiUrl}/DeleteSubcategory?id=${id}&code=${environment.apiKey}`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
