import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { LanguageService } from '../../services/language/language.service';
import {
  ActionTypes,
  LoadLanguagesAction,
  LoadLanguagesFailureAction,
  LoadLanguagesSuccessAction,
} from './actions';

@Injectable()
export class LanguageStoreEffects {
  constructor(
    private languageService: LanguageService,
    private actions$: Actions
  ) {}

  getLanguagesAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LoadLanguagesAction>(ActionTypes.LOAD_LANGUAGES),
      exhaustMap(() =>
        this.languageService.GetLanguages().pipe(
          map((items) => {
            return new LoadLanguagesSuccessAction(items);
          }),
          catchError((error) =>
            observableOf(new LoadLanguagesFailureAction(error.error))
          )
        )
      )
    );
  });
}
