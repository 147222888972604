import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_SLIDE_ASSETS_SUCCESS: {
      return {
        ...state,
        assets: action.payload.assets,
      };
    }
    case ActionTypes.SET_CURRENT_SLIDE: {
      return {
        ...state,
        current: action.payload.slide,
      };
    }
    case ActionTypes.SET_SLIDE_POSITION: {
      return {
        ...state,
        position: action.position,
      };
    }
    case ActionTypes.SAVE_SLIDE_SUCCESS: {
      return {
        ...state,
        error: undefined,
        saveSuccess: true,
      };
    }
    case ActionTypes.SAVE_SLIDE_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        saveSuccess: false,
      };
    }
    case ActionTypes.DELETE_SLIDE_SUCCESS: {
      return {
        ...state,
        error: undefined,
        deleteSuccess: true,
        duplicateSuccess: undefined,
      };
    }
    case ActionTypes.DELETE_SLIDE_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        deleteSuccess: false,
      };
    }
    case ActionTypes.PASTE_SLIDE_SUCCESS: {
      return {
        ...state,
        error: undefined,
        duplicateSuccess: true,
        deleteSuccess: undefined,
      };
    }
    case ActionTypes.PASTE_SLIDE_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        duplicateSuccess: false,
      };
    }
    case ActionTypes.SAVE_SLIDE_ASSETS_SUCCESS: {
      return {
        ...state,
        error: undefined,
        saveSuccess: true,
      };
    }
    case ActionTypes.SAVE_SLIDE_ASSETS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        saveSuccess: false,
      };
    }
    case ActionTypes.SAVE_TEMPORARY_SLIDE_ASSET_PROGRESS: {
      return {
        ...state,
        urlUpdate: undefined,
        progressUpdate: action.payload,
      };
    }

    case ActionTypes.SAVE_TEMPORARY_SLIDE_ASSET_URL_UPDATE: {
      return {
        ...state,
        urlUpdate: action.payload,
        progressUpdate: undefined,
      };
    }

    case ActionTypes.START_SLIDE: {
      return {
        ...state,
        position: 1,
        error: undefined,
        saveSuccess: undefined,
      };
    }
    case ActionTypes.CLOSE_SLIDE: {
      return {
        ...state,
        position: 0,
        isOpen: false,
        progressUpdate: undefined,
        urlUpdate: undefined,
        error: undefined,
        saveSuccess: undefined,
        deleteSuccess: undefined,
        duplicateSuccess: undefined,
      };
    }
    case ActionTypes.OPEN_SLIDE: {
      return {
        ...state,
        position: action.payload.position,
        isOpen: true,
      };
    }
    case ActionTypes.RESET_STATE_CHANGE: {
      return {
        ...state,
        error: undefined,
        progressUpdate: undefined,
        urlUpdate: undefined,
        saveSuccess: undefined,
        deleteSuccess: undefined,
        duplicateSuccess: undefined,
      };
    }
    case ActionTypes.SELECT_CATEGORY: {
      return {
        ...state,
        selectedCategory: action.payload.selectedCategory,
      };
    }
    case ActionTypes.CLEAR_CATEGORY: {
      return {
        ...state,
        selectedCategory: undefined,
      };
    }
    default: {
      return state;
    }
  }
}
