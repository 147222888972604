<div
  *ngIf="isLandscape || isPortrait"
  class="bg-brand-blue-800 fixed z-50 w-full h-full flex flex-col"
>
  <div class="p-small flex flex-row items-center justify-between">
    <div class="flex h-12 items-center">
      <img
        src="/assets/img/Reishauer_Icons-LogoWhite.svg"
        alt="Logo"
        class="h-10"
      />
    </div>
    <button (click)="toggleUserMenu()" type="button" class="p-2">
      <img
        src="/assets/icons/Reishauer_Icons-Close.svg"
        alt="Close"
        class="aspect-square h-8"
      />
    </button>
  </div>

  <div
    [ngClass]="isLandscape ? 'flex-row gap-16' : 'flex-col gap-10 items-center'"
    class="p-big flex justify-center"
  >
    <div class="w-full flex flex-col gap-3 max-w-[250px]">
      <h1 class="uppercase text-[#808fad]">
        {{ 'userMenu.account' | translate }}
      </h1>
      <p class="text-white">{{ user?.name }}</p>
      <button
        class="uppercase h-10 rounded-md w-full bg-white text-[#2c4476]"
        (click)="logout()"
      >
        {{ 'userMenu.logout' | translate }}
      </button>
    </div>
    <div
      [ngClass]="{
        'gap-3': isPortrait,
        'justify-between': isLandscape
      }"
      class="w-full flex flex-col max-w-[250px]"
    >
      <h1 class="uppercase text-[#808fad]">
        {{ 'userMenu.language' | translate }}
      </h1>
      <rh-language-dropdown-mobile
        *ngIf="languages && languages.length > 0 && selectedLanguage"
        [languages]="languages"
        [selectedLanguageKey]="selectedLanguage"
        (languageChanged)="changeLanguage($event.key)"
      ></rh-language-dropdown-mobile>
    </div>
  </div>
</div>
