import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';

import { OverlayModule } from '@angular/cdk/overlay';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { LoadingModule } from '@myreishauer/loading';
import { Store } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DragulaModule } from 'ng2-dragula';
import { environment } from '../environments/environment';
import { ErrorHandlerService } from './core/services/error-handling/error-handler.service';
import { PermissionsServiceMock } from './core/services/permissions/mocks/permissions.service.mock';
import { PermissionsService } from './core/services/permissions/permissions.service';
import {
  LoadCategoriesAction,
  SyncCategoriesAction,
} from './shared/store/category/actions';
import { LoadLanguagesAction } from './shared/store/language/actions';
import { LoadPermissionsAction } from './shared/store/permissions/actions';
import { State } from './shared/store/state';
import { LoadUserAction } from './shared/store/user/actions';
import { CommunicationService } from './shared/services/communication/communication.service';
import { ServiceWorkerModule } from '@angular/service-worker';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CoreModule,
    LoadingModule,
    OverlayModule,
    DragulaModule.forRoot(),
    AppRoutingModule,
    AuthModule.forRoot({
      domain: environment.domain,
      clientId: environment.clientId,
      authorizationParams: {
        redirect_uri: environment.redirectUri,
        audience: environment.audience,
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.backendApiUrl}/*`,
            tokenOptions: {
              authorizationParams: {
                audience: environment.audience,
              },
            },
          },
        ],
      },
    }),
    ServiceWorkerModule.register('sw.js', {
      registrationStrategy: 'registerImmediately',
      scope: '/',
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store<State>) => {
        return () => {
          store.dispatch(new LoadPermissionsAction());
          store.dispatch(new LoadUserAction());
          store.dispatch(new LoadCategoriesAction());
          store.dispatch(new SyncCategoriesAction());
          store.dispatch(new LoadLanguagesAction());
        };
      },
      multi: true,
      deps: [Store],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (service: CommunicationService) => {
        return () => {
          return service;
        };
      },
      multi: true,
      deps: [CommunicationService],
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: PermissionsService,
      useClass:
        environment.mockBackendServices === 'yes'
          ? PermissionsServiceMock
          : PermissionsService,
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
