import { IMediaAssetDto } from '../../../core/dto/media-asset-dto.model';

export interface State {
  isLoading?: boolean;
  progressUpdate?: number;
  assets: IMediaAssetDto[];
  temporaryAssets: IMediaAssetDto[];
  error?: string;
  editors: string[];
  copySlideSuccessMessage?: string;
}

export const initialState: State = {
  editors: [],
  assets: [],
  temporaryAssets: [],
};
