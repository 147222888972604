import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of as observableOf } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { AuthenticateService } from '../../../core/services/auth/auth.service';
import { User } from '../../models/user.model';
import { UserService } from '../../services/user/user.service';
import {
  ActionTypes,
  ChangeLanguageAction,
  ChangeLanguageFailureAction,
  ChangeLanguageSuccessAction,
  LoadApproversAction,
  LoadApproversFailureAction,
  LoadEditorsBySubCategoryAction,
  LoadEditorsBySubCategoryFailureAction,
  LoadEditorsBySubCategorySuccessAction,
  LoadUserAction,
  LoadUserFailureAction,
  LoadUserSuccessAction,
} from './actions';

@Injectable()
export class UserStoreEffects {
  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private actions$: Actions,
    private authService: AuthenticateService,
    private router: Router
  ) {}

  loadEditorsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadEditorsBySubCategoryAction>(
        ActionTypes.LOAD_EDITORS_BY_SUB_CATEGORY
      ),
      exhaustMap((action) =>
        this.userService.GetEditors(action.subCategoryName).pipe(
          map((items) => new LoadEditorsBySubCategorySuccessAction(items)),
          catchError((error) =>
            observableOf(new LoadEditorsBySubCategoryFailureAction(error.error))
          )
        )
      )
    )
  );

  loadApproversEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadApproversAction>(ActionTypes.LOAD_APPROVERS),
      exhaustMap(() =>
        this.userService.GetApprovers().pipe(
          map((items) => new LoadEditorsBySubCategorySuccessAction(items)),
          catchError((error) =>
            observableOf(new LoadApproversFailureAction(error.error))
          )
        )
      )
    )
  );

  loadUserEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadUserAction>(ActionTypes.LOAD_USER),
      exhaustMap(() =>
        this.authService.getUser().pipe(
          map(
            (user) =>
              new LoadUserSuccessAction({
                name: user.name,
                email: user.email,
                ui_locales: user.ui_locales,
                sub: user.sub,
              } as User)
          ),
          catchError((error) =>
            observableOf(new LoadUserFailureAction({ error: error.error }))
          )
        )
      )
    )
  );

  loadUserSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LoadUserSuccessAction>(ActionTypes.LOAD_USER_SUCCESS),
        switchMap((action) => this.translate.use(action.user.ui_locales))
      ),
    { dispatch: false }
  );

  changeLanguageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ChangeLanguageAction>(ActionTypes.CHANGE_LANGUAGE),
      tap((action) => {
        this.translate.use(action.languageKey);
      }),
      switchMap((action) =>
        this.userService
          .ChangeLanguageKey(action.languageKey, action.userId)
          .pipe(
            map(() => new ChangeLanguageSuccessAction()),
            catchError((error) =>
              observableOf(new ChangeLanguageFailureAction(error))
            )
          )
      )
    )
  );

  changeLanguageSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ChangeLanguageSuccessAction>(
          ActionTypes.CHANGE_LANGUAGE_SUCCESS
        ),
        tap(() => {
          this.router.navigate(['/']);
        })
      ),
    { dispatch: false }
  );
}
